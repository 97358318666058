import React from "react";
import cow1 from "../assets/cow1.jpg";
import cow2 from "../assets/cow2.jpg";
import BigBranch from "../assets/BigBranch.png";
import SmallBranch from "../assets/SmallBranch.png";
import { Link } from "react-router-dom";
import "./AnimalTree.css";

function AnimalTree() {
  return (
    <div className="canvas">
      <table align="center">
        <tbody>
          <tr>
            <td valign="middle" rowspan="8" colspan="1">
              <font size="+1">
                <a href="/AnimalDetails">
                  <img
                    className="tree-img"
                    src={cow1}
                    width="120"
                    height="80"
                    border="2"
                    alt="cow1 temporary"
                  />
                  <br />
                  <span className="second-gen">Animal name</span>
                </a>
              </font>
            </td>
            <td colspan="1" rowspan="8" valign="middle">
              <img
                src={BigBranch}
                width="28"
                height="60"
                alt="Big Branch For Tree"
              />
            </td>
            <td valign="middle" rowspan="4" colspan="1">
              <font size="+1">
                <a href="/AnimalDetails" className="third-gen">
                  Animal name
                </a>
              </font>
            </td>
            <td colspan="1" rowspan="4" valign="middle">
              <img
                src={SmallBranch}
                width="28"
                height="42"
                alt="Small Branch For Tree"
              />
            </td>
            <td colspan="1" rowspan="2" valign="Middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td valign="middle" rowspan="4" colspan="1">
              <font size="+1">
                <a href="/AnimalDetails" className="third-gen">
                  Animal name
                </a>
              </font>
            </td>
            <td valign="middle" rowspan="4" colspan="1">
              <img
                src={SmallBranch}
                width="28"
                height="42"
                alt="Small Branch For Tree"
              />
            </td>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td valign="middle" rowspan="8" colspan="1">
              <font size="+1">
                {" "}
                <a href="/AnimalDetails">
                  <img
                    className="tree-img"
                    src={cow2}
                    width="120"
                    height="80"
                    border="2"
                    alt="cow2 temporary"
                  />
                  <br />
                  <span className="second-gen">Animal name</span>{" "}
                </a>{" "}
              </font>
            </td>
            <td valign="Middle" rowspan="8" colspan="1">
              <img
                src={BigBranch}
                width="28"
                height="60"
                alt="Big Branch For Tree"
              />
            </td>
            <td valign="middle" rowspan="4" colspan="1">
              <font size="+1">
                <a href="/AnimalDetails" className="third-gen">
                  Animal name
                </a>
              </font>
            </td>
            <td valign="middle" rowspan="4" colspan="1">
              <img
                src={SmallBranch}
                width="28"
                height="42"
                alt="Small Branch For Tree"
              />
            </td>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td rowspan="2" colspan="1"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td valign="middle" rowspan="4" colspan="1">
              <font size="+1">
                <a href="/AnimalDetails" className="third-gen">
                  Animal name
                </a>
              </font>
            </td>
            <td valign="middle" rowspan="4" colspan="1">
              <img
                src={SmallBranch}
                width="28"
                height="42"
                alt="Small Branch For Tree"
              />
            </td>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td colspan="1" rowspan="2"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td colspan="1" rowspan="2" valign="middle" className="fourth-gen">
              Animal name
            </td>
            <td colspan="1" rowspan="2"></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AnimalTree;
