import React from "react";

function AnimalDetails() {
  return (
    <div className="animal-details">
      <h1>Animal Details Go Here</h1>
    </div>
  );
}

export default AnimalDetails;
