import React from "react";
import AnimalTree from "../components/AnimalTree";

function Home() {
  return (
    <div className="home">
      <AnimalTree />
    </div>
  );
}

export default Home;
